// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	.navi-main {
        width: 100%;
		li {
			text-transform: uppercase;
			
            ul.sub {
                li {
                    text-transform: none;
                }
            }
		}
	}


    // Off Canvas Styles
    //////////////////////////////

    @include breakpoint(giant, max) {
        background: $primary;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: rem($menu-bar);
        transform: translateX(-100%);
        transition: .6s;
        width:map-get($breakpoints, tiny) * .8;
        z-index: 895;
        bottom: 0;
        max-width: 100%;
        li {
            border-top:1px solid $light;
            width:100%;
            a {
                padding:1rem;
                display: block;
                width:100%;
                color:$light;
                &:hover, &.active {
                    background: $secondary;
                    color:$light;
                }
            }
            ul {
            	margin-left: 1rem;
            }
        }
        /* ///// li. hasSub SPAN /////*/

        li.hasSub span {
            padding:1rem;
            display: block;
            width:100%;
            color:$light;
           
            &:hover, &.active {
                background: $secondary;
                color:$light;
            }
        }

    }

    // DESKTOP Navi
    //////////////////////////////

    @include breakpoint(giant) {
        bottom:auto;
        left:auto;
        position: relative;
        top:auto;
        width: 100%;
        padding:0;
        overflow: visible;
        nav {
            width: 100%;  
            background: $primary;
            padding:1.5rem 1rem; 
        }
    .navi-main {   
        display: flex;
        justify-content:space-between;       
        position: relative;
        li {
            border-top:none;
            width:auto;
            position: relative;
            color:$light;

                a {
                    padding:0;
                    display: block;
                    color:$light;
                    font-size: 1.1rem;
                   
                    &:hover,  &.active {
                        background: none;
                        
                        transition:all .3s;
                    }

                    /* ///// BORDER STYLE ///// */
                    &:after {
                        content:'';
                        position: absolute;
                        width:100%;
                        height: 0;
                        opacity:0;
                         top:0;
                         transition:all .3s;
                         left:0;

                    }
                    &:hover,  &.active {
                        &:after {
                            content:'';
                            position: absolute;
                            width:100%;
                            height:2px;
                            background: $light;
                            display: block;
                            opacity:1;
                            top:1.8rem;
                        }
                    }
                    /* ///// END BORDER STYLE ///// */
                }
                 @include breakpoint(huge) {
                    font-size: 1.2rem;
                    a {
                         font-size: 1.2rem;
                    }
                }
            }


            /* ///// SUB NAVI Styles ///// */
            .sub {
                position: absolute;
                height:auto;
                padding: 0.8em;
                background:$primary;
                opacity: 0;
                transition: opacity 0.2s ;
                z-index: 100;
                li {
                    display: block;
                    height: 1.5em;
                    text-align: left;
                    width: rem(250px);
                    color:$primary;
                    height:0;
                    transition: height .2s ease ;
                    position: relative;
                }
                a {
                    color:$light;
                    font-size: 1rem;
                     &:hover,  &.active {
                        &:after {
                            height:1px;
                        }
                    }
                    
                }
            }
            li.hasSub {
                &:before {
                    content:'';
                    position: absolute;
                    width:14px;
                    height:8px;
                    background:url(/images/layout/arrow.gif) no-repeat;
                    display: block;
                    top: 13px;
                    right: -20px;
                }
                &.active:after {
                            content:'';
                            position: absolute;
                            width:100%;
                            height:2px;
                            background: $light;
                            display: block;
                            opacity:1;
                            top:1.8rem;
                        }
            }
            li.hasSub:hover li {
                height: 40px;
            }
            li.hasSub:hover ul.sub {
                opacity: 1;
            }

            /* ///// END SUB NAVI Styles ///// */

        }
         nav {
            &.fixed {
                position: fixed;
                top:0;
                left:0;
                width:100%;
                z-index:100;
            }
        }
    }

}

// Mobile Menu Bar
//////////////////////////////

.mobile-bar {
	align-items: center;
	background: $primary;
	display: flex;
	height: rem($menu-bar);
	justify-content: space-between;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 894;
	color:$light;
    li:first-child {
        display: none;
        @include breakpoint(small) {
        display: inline-block;
        }
    }
	li {
		display: flex;
		flex-grow: 1;
		padding: 0 rem($base-gap);

		&:first-child {

			i {
				transform: rotate(120deg);
			}
		}

		&:last-child {
            justify-content: flex-end;
        }

        label[for="navi-toggled"] {
			flex-direction: row-reverse;
			font-size: rem(18px);

			&:before {
				@extend .fa-solid;
				@extend .fa-bars;
				margin-right: rem(10px);
			}

			&:after {
				background: rgba($dark,0.8);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: fixed;
				top: rem($menu-bar);
				transition: opacity .6s, visibility .6s;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}
		}
	}

	@include breakpoint(giant) {
		display: none;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			
			&:before {
				@extend .fa-solid;
				@extend .fa-times;
			}

			&:after {
				opacity: 1;
				pointer-events: auto;
				visibility: visible;
			}
		}

		~ * .page-navi, ~ .page-navi {
			transform: translateX(0);
		}
	}
}