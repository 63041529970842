* {
	margin: 0;
	padding: 0;

	&, &:before, &:after {
		box-sizing: inherit;
	}
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);

	// Debug mode
	&.the-customer-page #log_hider {
		display: none;
	}

	&.ie-8 #pageloader:after {
		display: none;
	}
}

iframe {
	border: none;
	width: 100%;
}

/**
 * General Margin
 */
p, ol, dl, .margin-bottom {
	margin-bottom: rem($base-line-height);
}

a {
	text-decoration: none;
	transition: all .3s;
}

/**
 * Headlines
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@extend .margin-bottom;
	display: block;
	text-rendering: optimizeLegibility;
	font-weight: 600;
	color:#0050a3;
	span {
		font-weight: 300;
		color:$dark;
	}
	text-transform: uppercase;
}

h1, .h1 {
	font-size: rem(25px);
	line-height:1.2em;
	@include breakpoint(tiny) {
		font-size: rem(30px);
		line-height:1.8rem;
	}
}

h2, .h2 {
	font-size: rem(23px);
	line-height:1.2em;
	body:not(.index) & {
	    color:$dark;
		font-weight: 300;
		text-transform: none;
		@include breakpoint(huge) {
			font-size:rem(28px);
			line-height:rem(34px);
		}
	}
	
	@include breakpoint(tiny) {
		font-size:1.4rem;
		line-height:rem(32px);
	}
	@include breakpoint(huge) {
		font-size:rem(30px);
		line-height:rem(36px);
	}
}

h3, .h3 {
	font-size: rem($h3-size);
	@include breakpoint(small) {
		margin-bottom: 3rem;
	}
}

h4, .h4 {
	font-size: rem($h4-size);
}

h5, .h5 {
	font-size: rem($h5-size);
}

h6, .h6 {
	font-size: rem($h6-size);
}

/**
 * Links
 */
a {
	color:#0050a3;
	word-wrap: break-word;

	&:focus, &:hover, &:active {
		color: $secondary;
	}

	img {
		border: none;
	}

	&[href$=".pdf"]:before {
		@extend .fa-solid;
		@extend .fa-file-pdf;
		margin-right: .5em;
	}

	&[href^=tel] {
        color: inherit;
        text-decoration: underline;
    }
}

hr, .hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0;
	flex-basis: 100%;
}

ul {
	list-style: none;

	&.styled-list {
		@extend .margin-bottom;

		li {
			padding-left: rem(30px);
			margin-bottom: rem(7px);
			position: relative;
			&:before {
				background: url(/images/list.gif) no-repeat;
				content:'';
				width:15px;
				height:15px;
				display: inline-block;
				position: absolute;
				top:9px;
				left:0;

			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}

	&.unstyled-list {
		margin-bottom: 0;

		li {
			padding-bottom: 0;
			padding-left: 0;

			&:before {
				content: none;
			}
		}
	}
}

dl {
	
	&.styled-list {
		@include pie-clearfix;

		dt, dd {
			display: block;
			background: $medium;
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: 700;
		}

		dd {
			padding-top: 0;
			
			&:not(:last-child) {
				margin-bottom: rem(10px);
			}
		}
	}

	&.float-list {
		@include pie-clearfix;

		dt, dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: .5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
  list-style-type: none;
  counter-reset: item;

  li {
	padding-top: .3rem;
	padding-bottom: .3rem;
	display: table;
	counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: .3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {

			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
    float: none;
    height: auto;
    max-width: 100%;
    width: 100%;

    &[src^='http://cms.'],
    &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

    [data-rel] &, .lightbox-image & {
        margin-bottom: 0;
    }
}

/*
 * reset copyright link
 */
[href*='alpen-web.ch'],
[href*='bewertet.de'],
[href*='euroweb.at'],
[href*='euroweb.de'],
[href*='ew.de'],
[href*='geprueft.de'],
[href*='web2walk.com'],
[href*='webstyle.de'],
[href*='webstyle.com'],
[href*='wn-onlineservice.de'],
[href*='internet-media.com'],
[href*='yourrate.com'] {
    display: table;
    text-decoration: none;

    img {
        background: none;
        border-radius: 0;
        border: none;
        margin-bottom: rem(5px);
        outline: none;
        padding: 0;
        white-space: normal;
        width: auto !important;
    }
}

/**
 * Tables
 */

table {
	@extend .margin-bottom;
	display: table;
	border-collapse: collapse;
	width: 100%;
	max-width: 100%;
	text-align: left;
	font-size: rem($base-font-size);

	@include breakpoint(small, max) {
		overflow-x: auto;
		display: block;
	}

	caption {
		text-align: left;
		padding: rem($base-gap) rem($base-gap) rem(25px);
		display: table-caption;
		font-weight: 700;
		font-size: 1.2rem;
	}

	thead {
		border-collapse: collapse;
		display: table-header-group;
	}

	tbody {
		width: 100%;
		overflow-x: scroll; 
		display: table-row-group;

		tr {
			padding: rem($base-gap) 0;

			&:not(:last-child) {
				border-bottom: rem(1px) solid $medium;
			}
		}
	}

	tr {
		display: table-row;
	}

	th, td {
		padding: rem($base-gap);
		display: table-cell;
		vertical-align: top;
	}

	@include breakpoint(small, max) {

		td {
			min-width: 50vw;
		}
	}
}