// PAGE CONTAINER
//////////////////////////////

.pagecontainer {
	max-width:1790px;
	margin:0 auto;
	background:$light;
	box-shadow:0px 0px 16px 0px rgba(0,0,0,0.3);
	@include breakpoint(huge) {
	margin-top:1rem;
	}
}

// PAGE NAVI
//////////////////////////////

.page-navi {
	.top {
		display: none;

		@include breakpoint(giant) {
			display: block;
			padding:2rem 1rem;
		}
		@include breakpoint(huge) {
			padding:3rem 1rem;
		}
		.row {
			justify-content: space-between;
			.brandingcontainer, .right {
				display: flex;
				flex-wrap:wrap;
			}
			@include breakpoint(giant) {
				.right {
					ul {
						margin-left: 2rem;
						i {
							font-size: 0.9rem;
							margin-right: 0.5rem;
						}
						
					}
					ul:nth-child(2){
						line-height: .9rem;
						span {
							font-size: 0.9rem;
						}
					}
				}
			}
			@include breakpoint(huge) {
				.right {
					ul {
						margin-left:5rem;
						
					}
					
				}
			}
		}
	}
	
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	padding:0 1rem;
	width: 100%;

	body:not(.index) & {
		display: none;
	}
	@include breakpoint(giant) {
		padding:0;
		.brandingcontainer {
			display: none;
		}
	}
	
	.imagecontainer {
		display: flex;
		flex-wrap: wrap;
		div.cta {
			order:2;
			padding-left: 0;
			padding-right:0;
			br.br {
				display: none;
			}
			a {
				margin-bottom: 0;
			}
		}
		.slider {
			order:1;
			margin-bottom: 1rem;
			
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
		}
		@include breakpoint(medium) {
			position: relative;
			div.cta {
				position: absolute;
				top: 3rem;
				z-index: 50;
				left: 3rem;
				br.br {
					display: block;
				}
				h1 {
					color:$light;
					text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.56);
					span {
						color:$light;
					}
				}
				a {
					font-weight: 600;
					text-transform: uppercase;
				}
			}
		}
		@include breakpoint(large) {
			div.cta {
				text-align: center;
				top: 5rem;
				left:0;
				h1 {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}
				a {
					margin:0 auto;
				}
			}
			//transition: all .3s;
			&.fixed {

				margin-top: 90px;
			}
		}	
		@include breakpoint(giant) {
			div.cta {
				top: 7rem;
				h1 {
					font-size: 2.2rem;
					line-height: 2.6rem;
				}
			}
		}	
		@include breakpoint(huge) {
			div.cta {
				top:9rem;
				h1 {
					font-size: rem(48px);
					line-height: rem(50px);
				}
			}
		}	
		@include breakpoint(megahuge) {
			div.cta {
				top:13rem;
				h1 {
					font-size: 2.2rem;
					line-height: 2.6rem;
				}
			}
		}	
	}
}

// PAGE NAVI .TOP & HEADER
//////////////////////////////

.brandingcontainer {
	text-transform: uppercase;		
	font-size:rem(23px);
	ul {
		@extend .styled-list;
		li {
			margin-bottom: 0;
		}
	}
	@include breakpoint(small) {
		display: flex;
		flex-wrap:wrap;
		justify-content: center;
		align-items: center;
		padding:2rem 0;
		a.branding, ul {
			display: inline-block;
			margin:0;
			padding:0 .5rem;
		}
	}
	@include breakpoint(large) {
		padding:3rem 0;
	}
	@include breakpoint(giant) {
		padding:0;
	}
}

.branding {
	display: block;
	margin:2rem auto 1rem;
	text-align: left;
	@include breakpoint(small) {
		margin:1rem 0;
	}
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem($menu-bar);
	
	@include breakpoint(giant) {
		padding-top: 0;
	}
}


// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding:2rem 0;
	.einfamilienhaus-moenchengladbach &, .reihenhaus-doppelhaus-moenchengladbach &, .mehrfamilienhaus-moenchengladbach &, .betreutes-wohnen-moenchengladbach &, .geschaeftshaus-moenchengladbach &, .umbau-sanierung-moenchengladbach & {
		padding:2rem 1rem;
	}
	@include breakpoint(medium) {
		padding:3rem 0;
		.einfamilienhaus-moenchengladbach &, .reihenhaus-doppelhaus-moenchengladbach &, .mehrfamilienhaus-moenchengladbach &, .betreutes-wohnen-moenchengladbach &, .geschaeftshaus-moenchengladbach &, .umbau-sanierung-moenchengladbach & {
			padding:3rem 1rem;
		}
	}
	@include breakpoint(large) {
		padding:4rem 0;
		.einfamilienhaus-moenchengladbach &, .reihenhaus-doppelhaus-moenchengladbach &, .mehrfamilienhaus-moenchengladbach &, .betreutes-wohnen-moenchengladbach &, .geschaeftshaus-moenchengladbach &, .umbau-sanierung-moenchengladbach & {
			padding:4rem 1rem;
		}
	}
	@include breakpoint(giant) {
		.einfamilienhaus-moenchengladbach &, .reihenhaus-doppelhaus-moenchengladbach &, .mehrfamilienhaus-moenchengladbach &, .betreutes-wohnen-moenchengladbach &, .geschaeftshaus-moenchengladbach &, .umbau-sanierung-moenchengladbach & {
			padding:4rem 1rem;
			&.fixed {
				padding-top:9rem;
			}
		}

	}
	@include breakpoint(megahuge) {
		.einfamilienhaus-moenchengladbach &, .reihenhaus-doppelhaus-moenchengladbach &, .mehrfamilienhaus-moenchengladbach &, .betreutes-wohnen-moenchengladbach &, .geschaeftshaus-moenchengladbach &, .umbau-sanierung-moenchengladbach & {
			padding:4rem 0;
		}
	}
	@include breakpoint(giant) {
		&.fixed {
			padding-top:9rem;
		}
	}
	ul, dl {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
		
	}
	a {
		font-weight: 600;
	}
}

.index {
	main {
		
		padding:2rem 0 0;
		@include breakpoint(tiny) {
			.indexrow {
				margin-bottom:1rem;
			}
		}
		@include breakpoint(small) {
			.indexrow {
				margin-bottom:2rem;
			}
		}
		@include breakpoint(medium) {
			padding:3rem 0 0;
			.indexrow {
				margin-bottom:3rem;
			}
		}
		@include breakpoint(large) {
			padding:4rem 0 0;
			.indexrow {
				margin-bottom:4rem;
			}
		}
		@include breakpoint(huge) {
			padding:5rem 0 0;
			.indexrow {
				margin-bottom:5rem;
			}
		}
		h2 {
			background:$medium;
		    padding: 2rem 1rem;
		    @extend .hrcontainer;
		    margin-bottom: 2rem;
		    @include breakpoint(medium) {
		    	background:none;
		    	padding: 0 1rem;
		    	margin-bottom: 1rem;
		    }
		    @include breakpoint(huge) {
		    	font-size: rem(30px);
		    }
		}	
		.img-peter-kuck {
			text-align: center;
			img {
				max-width: 386px;
			}
		}
	}

	.slogan {
		background: $medium;
		padding:2rem 0;
		text-align: center;
		margin-top: 2rem;
		p {
			margin-bottom: 0;
			font-weight: 600;
			font-size: 1.2rem;
			small {
				font-weight:300;
				font-size: rem(22px);
			}
			@include breakpoint(tiny) {
				font-size:1.4rem;
				line-height:1.8rem;
			}
		}
		@include breakpoint(medium) {
			padding:3rem 0;
		}
		@include breakpoint(large) {
			padding:4rem 0;
		}
		@include breakpoint(huge) {
			padding:5rem 0;
			p {
				font-size: rem(36px);

			}
		}
	}
	.teaser {
		display: flex;
		flex-wrap:wrap;
		margin-top: 1rem;
		margin-bottom: 1rem;
		div {
			position: relative;
			
			div.teasercontainer {
				width:100%;
				min-height: 300px;
				transition: all .3s;
				opacity: 1;
				position: relative;
				margin-bottom: 1rem;
				a {
					text-align:center;
					display: flex;
					align-items:center;
					height:100%;
					width: 100%;
					justify-content: center;
					font-size:1.2rem;
					position: relative;
					font-weight: 300;
					background: rgba(0,0,0,.5);
					min-height:300px;
					span {
						text-align:center;
						text-transform: uppercase;
						color:$light;
						position: absolute;
						transition: all 0.3s linear;
						top:50%;	
						
					}
					&:hover {
						background: rgba(0,0,0,0);
						opacity: 1;
						span {
							top:80%;
						}
					}
					
					

				}
				
				
			}
		}
		div:nth-child(1) div.teasercontainer {background: url(/images/index/teaser/einfamilienhaus.jpg) no-repeat center/cover;}
		div:nth-child(2) div.teasercontainer {background: url(/images/index/teaser/doppelhaus.jpg) no-repeat center/cover;}
		div:nth-child(3) div.teasercontainer {background: url(/images/index/teaser/mehrfamilienhaus.jpg) no-repeat center/cover;}
		div:nth-child(4) div.teasercontainer {background: url(/images/index/teaser/betreutes-wohnen.jpg) no-repeat center/cover;}
		div:nth-child(5) div.teasercontainer {background: url(/images/index/teaser/geschaeftshaus.jpg) no-repeat center/cover;}
		div:nth-child(6) div.teasercontainer {background: url(/images/index/teaser/umbau-sanierung.jpg) no-repeat center/cover;}
		
		@include breakpoint(huge) {
			div {
				height:440px;
			}	
			div:nth-child(1), div:nth-child(2), div:nth-child(3) {
				margin-bottom:2rem;
			}
		}
	}
	

	.aktuelles {
		width: 100%;
		display: flex;
		flex-wrap:wrap;
		position: relative;
		header {
			display: flex;
			flex-wrap:wrap;
			h3 {
				color:$light;
				margin-bottom: 0;
				background: $primary;
				padding:1rem;
				width:100%;
			}
			.textcontainer {
				padding: 2rem 0 1rem;
				background:$medium;
			}
		}
		.slider-aktuelles {
			width:100%;
			.slick-list {
				width:100%;
			}
		}
		@include breakpoint(huge) {
			header {
				width:50%;
				position:relative;
				h3 {
					position: absolute;
					width: 380px;
					height: 80px;
					transform: rotate(-90deg);
					left: -150px;
					top: 150px;
				}
				.textcontainer {
					padding: 2rem 2rem 1rem 5rem;
					background:$medium;
				}
			}
			.slider-aktuelles {
				width:50%;
			}
		}
		@include breakpoint(megahuge) {
			header {
				width:33.33%;
			}
			.slider-aktuelles {
				width:66.67%;
			}
		}
	}
}

body.iexplore.index .teaser div div.teasercontainer a span {
	left:2rem;
}

.leistungen-container {
		padding:2rem 0 0;
		@include breakpoint(small) {
			padding:3rem 0;
		}
		@include breakpoint(large) {
			padding:4rem 0;
		}
		@include breakpoint(large) {
			padding:5rem 0;
		}
		.row {
			.col {
				text-align:center;
				h3 {
					@extend .hrcontainer;
				}
				.teaser-container {
					div {
						position: relative;
						span {
							display: block;
							position: relative;
							text-align: center;
							margin-bottom: 2rem;
							padding-bottom:2rem;
							border-bottom:1px solid #bfbfbf;
							&:before {
							    content:'';
							    top:0;
							    left:calc(50% - 31.5px);
							    height:50px;
							    width:63px;
							    display:block;
							    position: relative;
							    background:url(/images/index/icons/entwurfzeichnung-.png) center top;
							}	
						}
						&:last-child span {
							border-bottom: none;
						}
						&:nth-child(2) span:before{
							background:url(/images/index/icons/baugenehmigungsplanung.png) center top;
						}
						&:nth-child(3) span:before{
							background:url(/images/index/icons/ausfuehrungsplanung.png) center top;
						}
						&:nth-child(4) span:before{
							background:url(/images/index/icons/ausschreibung.png) center top;
						}
						&:nth-child(5) span:before{
							background:url(/images/index/icons/vergabe.png) center top;
						}
						&:nth-child(6) span:before{
							background:url(/images/index/icons/bauleitung.png) center top;
						}
						@include breakpoint(small) {
							padding:2rem 0;
							span {
								margin-bottom: 0;
								padding-bottom:0;
								border-bottom: none;
							}
							&:before {
							    content:'';
							    top:0;
							    right:0;
							    height:1px;
							    width:100%;
							    display:block;
							    position: absolute;
							    background:#bfbfbf;
							}
							&:after {
							    content:'';
							    top:0;
							    right:0;
							    height:100%;
							    width:1px;
							    display:block;
							    position: absolute;
							    background:#bfbfbf;
							}
							&:nth-child(even):after{
								display: none;
							}
							&:nth-child(1), &:nth-child(2) {
								&:before {
								    display:none;
								}
							}
						}
						@include breakpoint(medium) {
							padding:3rem 0;
						}
						@include breakpoint(large) {
							padding:4rem 0;
						}
						@include breakpoint(giant) {
							&:nth-child(even):after{
								display: block;
							}
							&:nth-child(3) {
								&:before {
								    display:none;
								}
							}
							&:nth-child(3),&:nth-child(6) {
								&:after {
								    display:none;
								}
							}
						}
					}
					
				}
			}
		}
	}


// FOOTER
//////////////////////////////

.footer {
	display: block;
	.fragen {
		font-weight: 600;
		text-transform: uppercase;
		background: $medium;
		padding:2rem 0;
		@extend .hrcontainer;
		p {
			margin-bottom: 0;
			span {
				color:$primary;
			}
		}
		@include breakpoint(medium) {
			padding:3rem 0;
		}
		@include breakpoint(large) {
			padding:4rem 0;
		}
		@include breakpoint(huge) {
			padding:5rem 0;
		}
	}
	.google-maps {
		@include breakpoint(medium) {
			height: rem(250px);
			iframe{
				height: 100%;
			}
		}
		@include breakpoint(giant) {
			height: rem(300px);
		}
		@include breakpoint(huge) {
			height: rem(345px);
		}
	}
	.bottom {
		padding:.5rem 0 2rem;
		text-align:center;
		.row {
			img {
				width: auto;
			}
			ul, div {
				margin-top: 1rem;
			}
			div {
				ul {
					display: flex;
					flex-wrap:wrap;
					justify-content: center;
					li {
						padding:0 1rem 0 0;
						a {
							color:$dark;
							&.active, &:hover {
								color:$primary;
							}
						}
					}
				}
			}
		}
		@include breakpoint(small) {
			font-size:0.85rem;
			line-height: 1.3rem;
			img {
				float: left;
				margin-right: 1rem;
			}
			.row {
				div {
					ul {
						margin-top: 0;
					}
					margin-top: 1rem;
					img {
						float:none;
						margin-bottom:0;
					}
					ul {
						display: inline-flex;
						justify-content: flex-start;
					}
				}
				
			}
			
		}
		@include breakpoint(medium) {	
			font-size: 1rem;
			line-height: rem(30px);
			padding:3rem 0 4rem;
			.row {
				div {
					margin-top: 2rem;
				}
				ul {
					margin-top: 0;
				}
				img {
					margin-bottom: .5rem;
				}

			}
		}
		@include breakpoint(large) {
			padding:4rem 0 5rem;
			text-align:left;
			.row {
				align-items: center;
				ul:nth-child(2){
					li {
						span {
							width:80px;
							float: left;
						}
						span.phone {
							width: auto;
							float: none;
						}
						
					}
				}
				div {
					margin-top: 0;
					img {
						margin-bottom: .3rem;
					}
					ul {
						li {
							a {
								color:$dark;
							}
						}
					}
				}
				
			}
		}
		@include breakpoint(giant) {	
			.row {
				div, ul {

					
					li {
						span {
							width:70px;
   							 float:left;
						}
					}
				}
				div {
					img {
						margin-right: 0;
						float: right;
					}
					ul {
						float: right;
						text-align: right;
						li:last-child{
							padding-right: 0;
						}
					}
				}
			}
		}
		@include breakpoint(huge) {
			padding:5rem 0 6rem;
		}
	}
}

// KLASSEN
//////////////////////////////
.handwrite {
	font-family:$handwrite-font;
	font-size: 1.8rem;
	color:$primary;
}

.hrcontainer {
	font-size: 1.2rem;
	@include breakpoint(tiny) {
		font-size: 1.4rem;
		line-height:1.8rem;
	}
	span {
		width:100%;
		display: inline;
		@include breakpoint(tiny) {
		display: block;
		}
	}
	span.line {
		display: none;
	}
	@include breakpoint(medium) {
		span {
			width:auto;
			display: inline;
		}
		span.line {
			display: inline;
		}
	}
}

.projektcontainer {
	padding:1rem 0;
	@include breakpoint(medium) {
		padding:2rem 0;
	}
	@include breakpoint(large) {
		padding:3rem 0;
	}

}


